import { toast } from 'react-toastify';
import {
  DN_API_COUNTRIES,
  DN_API_OPTION_RULE_TYPES,
} from '../../routes/apiRoutes';

export const ACTIONS = {
  SET_OPTION_COUNTRY_REGION: 'SET_OPTION_COUNTRY_REGION',
  SET_OPTION_RULE_TYPE: 'SET_OPTION_RULE_TYPE',
};

export const setOptionCountryRegion = (data) => ({
  type: ACTIONS.SET_OPTION_COUNTRY_REGION,
  data,
});
export const setOptionRuleType = (data) => ({
  type: ACTIONS.SET_OPTION_RULE_TYPE,
  data,
});

// Action creators using Thunk middleware.
export const initOptionCountryRegion = () => {
  return (dispatch, getState, { axiosRequest }) => {
    return axiosRequest
      .get(DN_API_COUNTRIES)
      .then((response) => {
        if (response.status === 200 && response.data !== null) {
          dispatch(setOptionCountryRegion(response.data));
        } else {
          toast.error('There is an error while processing your request.');
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
};
export const initOptionRuleTypes = () => {
  return (dispatch, getState, { axiosRequest }) => {
    return axiosRequest
      .get(DN_API_OPTION_RULE_TYPES)
      .then((response) => {
        if (response.status === 200) {
          const tempData = [];

          response.data.map((item) => {
            tempData.push({
              label: item.name,
              value: item.code,
            });
            return null;
          });

          dispatch(setOptionRuleType(tempData));
        } else {
          toast.error('There is an error while processing your request.');
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
};
