import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CHeader,
  CFooter,
  CSidebar,
  CSidebarBrand,
  CSidebarMinimizer,
} from '@coreui/react';
// sidebar nav config
import Cookies from 'js-cookie';
import { COOKIE_USER_TOKEN, DEFAULT_PATH } from '../../utils/constants';
import LOGO from '../../assets/images/Alpha-Networks-Logo-White.png';
import SidebarNav from '../Nav';
// routes config
import routes from '../Routes/routes';

const DefaultFooter = React.lazy(() => import('./Footer'));
const DefaultHeader = React.lazy(() => import('./Header'));

const DefaultLayout = (props) => {
  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  const signOut = (e) => {
    e.preventDefault();
    Cookies.remove(COOKIE_USER_TOKEN);
    props.history.push('/login');
  };

  return (
    <>
      <CSidebar id="sidebar" fixed>
        <CSidebarBrand>
          <img src={LOGO} alt="Alpha Networks" />
        </CSidebarBrand>
        <SidebarNav />
        <CSidebarMinimizer />
      </CSidebar>
      <div className="c-wrapper">
        <CHeader fixed>
          <Suspense fallback={loading()}>
            <DefaultHeader onLogout={(e) => signOut(e)} />
          </Suspense>
        </CHeader>
        <div className="c-body">
          <main className="c-main">
            <Container fluid>
              <Suspense fallback={loading()}>
                <Switch>
                  {routes.map((route) => {
                    return route.component ? (
                      <Route
                        key={route.name}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        // eslint-disable-next-line no-shadow
                        render={(props) => (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <route.component {...props} routes={route.routes} />
                        )}
                      />
                    ) : null;
                  })}
                  <Redirect from="/" to={DEFAULT_PATH} />
                </Switch>
              </Suspense>
            </Container>
          </main>
        </div>
        <CFooter>
          <Suspense fallback={loading()}>
            <DefaultFooter />
          </Suspense>
        </CFooter>
      </div>
    </>
  );
};

DefaultLayout.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default DefaultLayout;
