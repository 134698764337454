import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { COOKIE_USER_TOKEN } from '../../utils/constants';

const SecuredRoute = (props) => {
  const { location = {} } = props;
  let claims;
  try {
    claims = jwtDecode(Cookies.get(COOKIE_USER_TOKEN));
  } catch {
    Cookies.remove(COOKIE_USER_TOKEN);
  }

  return claims ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};

SecuredRoute.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default SecuredRoute;
