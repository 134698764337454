const {
  REACT_APP_DNAPI_BASE_URL: DNAPI_BASE_URL,
  REACT_APP_DNAPI_API_PATH: DNAPI_API_PATH,
  REACT_APP_CDNAPP_BASE_URL: CDNAPP_BASE_URL,
  REACT_APP_CDNAPP_API_PATH: CDNAPP_API_PATH,
} = window.env || process.env;

const API_AUTH = `${DNAPI_BASE_URL}/auth`;

const DN_API_URL = `${DNAPI_BASE_URL}/${DNAPI_API_PATH}`;

const DN_API_NODES = `${DN_API_URL}/nodes`;
const DN_API_NODE_COUNT = `${DN_API_URL}/nodes/count`;
const DN_API_DOMAINS = `${DN_API_URL}/domains`;
const DN_API_DOMAIN_COUNT = `${DN_API_URL}/domains/count`;
const DN_API_SUB_DOMAINS = `${DN_API_URL}/sub-domains`;
const DN_API_RULES = `${DN_API_URL}/rules`;
const DN_API_COUNTRIES = `${DN_API_URL}/options/countries`;
const DN_API_OPTION_RULE_TYPES = `${DN_API_URL}/options/rule-types`;
const DN_API_GET_RULES_BY_DOMAIN = `${DN_API_URL}/domains/rules`;
const DN_API_UPDATE_RULE_STATUS = `${DN_API_URL}/rules/{ruleId}/availability/{status}`;
const DN_API_UPDATE_DOMAIN_RULES_STATUS = `${DN_API_URL}/domains/rules/availability/{status}`;

const CDN_API_URL = `${CDNAPP_BASE_URL}/${CDNAPP_API_PATH}`;

const CDN_API_DOMAIN = `${CDN_API_URL}/domains`;
const CDN_API_DOMAIN_COUNT = `${CDN_API_URL}/domains/count`;
const CDN_API_DOMAIN_ITEM = `${CDN_API_URL}/domains/{domainId}`;
const CDN_API_DOMAIN_STATUS = `${CDN_API_URL}/domains/{domainId}/availability/{status}`;
const CDN_API_DOMAIN_CREATE = `${CDN_API_URL}/domains/cdns`;
const CDN_API_CDN = `${CDN_API_URL}/domains/{domainId}/cdns`;
const CDN_API_CDN_ITEM = `${CDN_API_URL}/domains/{domainId}/cdns/{cdnId}`;
const CDN_API_CDN_STATUS = `${CDN_API_URL}/domains/{domainId}/cdns/{cdnId}/availability/{status}`;

const CDN_API_CDN_COUNTRIES = `${CDN_API_URL}/domains/{domainId}/cdns/countries`;
const CDN_API_CDN_REGIONS = `${CDN_API_URL}/domains/{domainId}/cdns/regions`;
const CDN_API_COUNTRY_REGION = `${CDN_API_URL}/regions-countries`;

const CDN_API_CDN_ACCESSIBILITY = `${CDN_API_URL}/domains/{domainId}/cdns/{cdnId}/accessibility`;
const CDN_API_CDN_ACCESSIBILITY_ENABLE = `${CDN_API_URL}/domains/{domainId}/cdns/{cdnId}/accessibility/{status}`;
const CDN_API_CDN_ACCESSIBILITY_DELETE = `${CDN_API_URL}/domains/{domainId}/cdns/{cdnId}/accessibility`;

const CDN_API_PROVIDERS = `${CDN_API_URL}/cdn-providers`;

export {
  API_AUTH,
  DNAPI_BASE_URL,
  DN_API_URL,
  DN_API_NODES,
  DN_API_NODE_COUNT,
  DN_API_DOMAINS,
  DN_API_DOMAIN_COUNT,
  DN_API_SUB_DOMAINS,
  DN_API_RULES,
  DN_API_COUNTRIES,
  DN_API_OPTION_RULE_TYPES,
  DN_API_GET_RULES_BY_DOMAIN,
  DN_API_UPDATE_RULE_STATUS,
  DN_API_UPDATE_DOMAIN_RULES_STATUS,
  CDNAPP_BASE_URL,
  CDN_API_URL,
  CDN_API_DOMAIN,
  CDN_API_DOMAIN_COUNT,
  CDN_API_DOMAIN_ITEM,
  CDN_API_DOMAIN_STATUS,
  CDN_API_DOMAIN_CREATE,
  CDN_API_CDN,
  CDN_API_CDN_ITEM,
  CDN_API_CDN_STATUS,
  CDN_API_PROVIDERS,
  CDN_API_CDN_COUNTRIES,
  CDN_API_CDN_REGIONS,
  CDN_API_COUNTRY_REGION,
  CDN_API_CDN_ACCESSIBILITY,
  CDN_API_CDN_ACCESSIBILITY_ENABLE,
  CDN_API_CDN_ACCESSIBILITY_DELETE,
};
