import { types } from '../actions/cdnRegionActions';

const getCdnCountryObj = (cdnCountries) => {
  const obj = {};
  cdnCountries.forEach((item) => {
    if (obj[item.country_code]) {
      obj[item.country_code] = [...obj[item.country_code], item];
    } else {
      obj[item.country_code] = [item];
    }
  });
  return obj;
};

const getCdnRegionObj = (cdnRegions) => {
  const obj = {};
  cdnRegions.forEach((item) => {
    if (obj[item.region_code]) {
      obj[item.region_code] = [...obj[item.region_code], item];
    } else {
      obj[item.region_code] = [item];
    }
  });
  return obj;
};

const formatCdnRegionResponse = (regions) =>
  regions.map((region) => {
    const { countries = [] } = region;
    const children = countries.map((country) => ({
      code: country.code,
      name: country.name,
      type: 'country',
      children: [],
    }));

    return {
      code: region.code,
      name: region.name,
      type: 'region',
      children,
    };
  });

// Prepare variables.
const initialState = {
  isLoading: false,
  itemList: [],
  countryRegions: [],
  countryRegionOptions: [],
  cdnCountries: [],
  cdnCountryObj: {},
  cdnRegions: [],
  cdnRegionObj: {},
  selectedItem: null,
  selectedGroupItem: null,
  editGroupForm: null,
  isModalOpen: {
    add: false,
    edit: false,
    enable: false,
    delete: false,
  },
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case types.CDN_REGION_OPERATION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case types.LIST_CDN_COUNTRY_REGION_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.LIST_CDN_COUNTRY_REGION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countryRegions: formatCdnRegionResponse(data),
      };
    case types.LIST_CDN_COUNTRY_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.LIST_CDN_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cdnCountries: data,
        cdnCountryObj: getCdnCountryObj(data),
      };
    case types.LIST_CDN_REGION_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.LIST_CDN_REGION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cdnRegions: data,
        cdnRegionObj: getCdnRegionObj(data),
      };
    case types.SET_CDN_REGION_ITEM_LIST:
      return {
        ...state,
        itemList: data,
      };
    case types.SET_COUNTRY_REGION_OPTIONS:
      return {
        ...state,
        countryRegionOptions: data,
      };
    case types.TOGGLE_ADD_CDN_COUNTRY_REGION_MODAL:
      return {
        ...state,
        isModalOpen: {
          add: !state.isModalOpen.add,
          edit: false,
          enable: false,
          delete: false,
        },
      };
    case types.ADD_CDN_COUNTRY_REGION_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_CDN_COUNTRY_REGION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          add: false,
          edit: false,
          enable: false,
          delete: false,
        },
      };
    case types.TOGGLE_EDIT_CDN_COUNTRY_REGION_MODAL:
      return {
        ...state,
        selectedGroupItem: data.item,
        editGroupForm: data.form,
        isModalOpen: {
          add: false,
          edit: !state.isModalOpen.edit,
          enable: false,
          delete: false,
        },
      };
    case types.EDIT_CDN_COUNTRY_REGION_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.EDIT_CDN_COUNTRY_REGION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          add: false,
          edit: false,
          enable: false,
          delete: false,
        },
      };
    case types.TOGGLE_DELETE_CDN_COUNTRY_REGION_MODAL:
      return {
        ...state,
        selectedGroupItem: data,
        isModalOpen: {
          add: false,
          edit: false,
          enable: false,
          delete: !state.isModalOpen.delete,
        },
      };
    case types.DELETE_CDN_COUNTRY_REGION_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.DELETE_CDN_COUNTRY_REGION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          add: false,
          edit: false,
          enable: false,
          delete: false,
        },
      };
    case types.TOGGLE_ENABLE_CDN_COUNTRY_REGION_MODAL:
      return {
        ...state,
        selectedItem: data,
        isModalOpen: {
          add: false,
          edit: false,
          enable: !state.isModalOpen.enable,
          delete: false,
        },
      };
    case types.ENABLE_CDN_COUNTRY_REGION_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ENABLE_CDN_COUNTRY_REGION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          add: false,
          edit: false,
          enable: false,
          delete: false,
        },
      };
    default:
      return state;
  }
};
export { getCdnRegionObj, getCdnCountryObj, formatCdnRegionResponse };
