import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import {
  CDN_API_DOMAIN,
  CDN_API_DOMAIN_CREATE,
  CDN_API_DOMAIN_STATUS,
  CDN_API_DOMAIN_ITEM,
  CDN_API_DOMAIN_COUNT,
  CDN_API_PROVIDERS,
} from '../../routes/apiRoutes';
import { DEFAULT_SHOW_PER_PAGE } from '../../utils/constants';

const types = {
  LIST_CDN_DOMAIN_START: 'LIST_CDN_DOMAIN_START',
  LIST_CDN_DOMAIN_SUCCESS: 'LIST_CDN_DOMAIN_SUCCESS',
  CDN_DOMAIN_OPERATION_FAILED: 'CDN_DOMAIN_OPERATION_FAILED',

  LIST_CDN_DOMAIN_PROVIDER_START: 'LIST_CDN_DOMAIN_PROVIDER_START',
  LIST_CDN_DOMAIN_PROVIDER_SUCCESS: 'LIST_CDN_DOMAIN_PROVIDER_SUCCESS',

  COUNT_CDN_DOMAIN_START: 'COUNT_CDN_DOMAIN_START',
  COUNT_CDN_DOMAIN_SUCCESS: 'COUNT_CDN_DOMAIN_SUCCESS',

  TOGGLE_CREATE_CDN_DOMAIN_MODAL: 'TOGGLE_CREATE_CDN_DOMAIN_MODAL',
  CREATE_CDN_DOMAIN_START: 'CREATE_CDN_DOMAIN_START',
  CREATE_CDN_DOMAIN_SUCCESS: 'CREATE_CDN_DOMAIN_SUCCESS',

  TOGGLE_DELETE_CDN_DOMAIN_MODAL: 'TOGGLE_DELETE_CDN_DOMAIN_MODAL',
  DELETE_CDN_DOMAIN_START: 'DELETE_CDN_DOMAIN_START',
  DELETE_CDN_DOMAIN_SUCCESS: 'DELETE_CDN_DOMAIN_SUCCESS',

  TOGGLE_ENABLE_CDN_DOMAIN_MODAL: 'TOGGLE_ENABLE_CDN_DOMAIN_MODAL',
  ENABLE_CDN_DOMAIN_START: 'ENABLE_CDN_DOMAIN_START',
  ENABLE_CDN_DOMAIN_SUCCESS: 'ENABLE_CDN_DOMAIN_SUCCESS',
};

const listCdnDomainStart = () => ({ type: types.LIST_CDN_DOMAIN_START });
const listCdnDomainSuccess = (domains) => ({
  type: types.LIST_CDN_DOMAIN_SUCCESS,
  data: domains,
});
const cdnDomainOperationFailed = (data) => ({
  type: types.CDN_DOMAIN_OPERATION_FAILED,
  data,
});

const listCdnDomainProviderStart = () => ({
  type: types.LIST_CDN_DOMAIN_PROVIDER_START,
});
const listCdnDomainProviderSuccess = (providers) => ({
  type: types.LIST_CDN_DOMAIN_PROVIDER_SUCCESS,
  data: providers,
});

const countCdnDomainStart = () => ({ type: types.COUNT_CDN_DOMAIN_START });
const countCdnDomainSuccess = (totalCount) => ({
  type: types.COUNT_CDN_DOMAIN_SUCCESS,
  data: totalCount,
});

const toggleCreateCdnDomainModal = () => ({
  type: types.TOGGLE_CREATE_CDN_DOMAIN_MODAL,
});
const createCdnDomainStart = (domain) => ({
  type: types.CREATE_CDN_DOMAIN_START,
  data: domain,
});
const createCdnDomainSuccess = (data) => ({
  type: types.CREATE_CDN_DOMAIN_SUCCESS,
  data,
});

const toggleDeleteCdnDomainModal = (domain) => ({
  type: types.TOGGLE_DELETE_CDN_DOMAIN_MODAL,
  data: domain,
});

const deleteCdnDomainStart = (domain) => ({
  type: types.DELETE_CDN_DOMAIN_START,
  data: domain,
});

const deleteCdnDomainSuccess = (data) => ({
  type: types.DELETE_CDN_DOMAIN_SUCCESS,
  data,
});

const toggleEnableCdnDomainModal = (domain) => ({
  type: types.TOGGLE_ENABLE_CDN_DOMAIN_MODAL,
  data: domain,
});

const enableCdnDomainStart = (domain, toVal) => ({
  type: types.ENABLE_CDN_DOMAIN_START,
  data: { domain, toVal },
});

const enableCdnDomainSuccess = (data) => ({
  type: types.ENABLE_CDN_DOMAIN_SUCCESS,
  data,
});

const listCdnDomain = (query) => (dispatch, _getState, { axiosRequest }) => {
  const urlParams = new URLSearchParams(query);
  const page = parseInt(urlParams.get('page'), 10) || 1;
  const name = urlParams.get('name');

  const limit = Cookies.get('SHOW_PER_PAGE') || DEFAULT_SHOW_PER_PAGE;

  dispatch(listCdnDomainStart());

  return axiosRequest
    .get(CDN_API_DOMAIN, { params: { page, limit, name } })
    .then((response) => {
      if (response.status === 200) {
        dispatch(listCdnDomainSuccess(response.data));
      }
    })
    .catch((error) => {
      dispatch(cdnDomainOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const listCdnDomainProvider = () => (dispatch, _getState, { axiosRequest }) => {
  dispatch(listCdnDomainProviderStart());
  return axiosRequest
    .get(CDN_API_PROVIDERS)
    .then((response) => {
      if (response.status === 200) {
        dispatch(listCdnDomainProviderSuccess(response.data));
      }
    })
    .catch((error) => {
      dispatch(cdnDomainOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const countCdnDomain = (query) => (dispatch, _getState, { axiosRequest }) => {
  dispatch(countCdnDomainStart());

  const urlParams = new URLSearchParams(query);
  const name = urlParams.get('name');

  return axiosRequest
    .get(CDN_API_DOMAIN_COUNT, { params: { name } })
    .then((response) => {
      if (response.status === 200) {
        dispatch(countCdnDomainSuccess(response.data));
      }
    })
    .catch((error) => {
      dispatch(cdnDomainOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const createCdnDomain = (data, query) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  dispatch(createCdnDomainStart(data));
  const url = CDN_API_DOMAIN_CREATE;
  return axiosRequest
    .post(url, data)
    .then((response) => {
      if (response.status === 201) {
        dispatch(createCdnDomainSuccess(response.data));
        dispatch(countCdnDomain());
        dispatch(listCdnDomain(query));
      } else {
        const message = `Creation return with status ${response.status}, expected 201`;
        toast.error(message);
        dispatch(cdnDomainOperationFailed(new Error(message)));
      }
    })
    .catch((error) => {
      dispatch(cdnDomainOperationFailed(error));
      let message = 'There is an error while processing your request.';
      if (error.response) {
        if (error.response.status === 409 || error.response.status === 500) {
          message = error.response.data.error;
        } else if (error.response.status === 422) {
          message = error.response.data.errors;
        }
      }
      toast.error(message);
    });
};

const deleteCdnDomain = (domain, query) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  dispatch(deleteCdnDomainStart(domain));
  const url = CDN_API_DOMAIN_ITEM.replace('{domainId}', domain.id);
  return axiosRequest
    .delete(url)
    .then((response) => {
      if (response.status === 202) {
        dispatch(deleteCdnDomainSuccess(response.data));
        dispatch(countCdnDomain());
        dispatch(listCdnDomain(query));
      }
    })
    .catch((error) => {
      dispatch(cdnDomainOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const enableCdnDomain = (domain, toVal) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  const url = CDN_API_DOMAIN_STATUS.replace('{domainId}', domain.id).replace(
    '{status}',
    toVal
  );
  dispatch(enableCdnDomainStart(domain, toVal));
  return axiosRequest
    .put(url)
    .then((response) => {
      if (response.status === 202) {
        dispatch(enableCdnDomainSuccess(response.data));
        dispatch(listCdnDomain());
      }
    })
    .catch((error) => {
      dispatch(cdnDomainOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

export default {
  listCdnDomain,
  listCdnDomainStart,
  listCdnDomainSuccess,
  cdnDomainOperationFailed,

  listCdnDomainProviderStart,
  listCdnDomainProviderSuccess,

  countCdnDomainStart,
  countCdnDomainSuccess,

  createCdnDomain,
  toggleCreateCdnDomainModal,
  createCdnDomainStart,
  createCdnDomainSuccess,

  deleteCdnDomain,
  toggleDeleteCdnDomainModal,
  deleteCdnDomainStart,
  deleteCdnDomainSuccess,

  enableCdnDomain,
  listCdnDomainProvider,
  toggleEnableCdnDomainModal,
  enableCdnDomainStart,
  enableCdnDomainSuccess,
  countCdnDomain,
};
export { types };
