import { toast } from 'react-toastify';
import {
  CDN_API_CDN,
  CDN_API_CDN_STATUS,
  CDN_API_PROVIDERS,
  CDN_API_DOMAIN,
  CDN_API_DOMAIN_STATUS,
  CDN_API_CDN_ITEM,
  CDN_API_DOMAIN_CREATE,
} from '../../routes/apiRoutes';

const types = {
  LIST_CDN_START: 'LIST_CDN_START',
  LIST_CDN_SUCCESS: 'LIST_CDN_SUCCESS',
  CDN_OPERATION_FAILED: 'CDN_OPERATION_FAILED',

  LIST_CDN_PROVIDER_START: 'LIST_CDN_PROVIDER_START',
  LIST_CDN_PROVIDER_SUCCESS: 'LIST_CDN_PROVIDER_SUCCESS',

  SET_CDN_ITEM_LIST: 'SET_CDN_ITEM_LIST',

  GET_CDN_DOMAIN_START: 'GET_CDN_DOMAIN_START',
  GET_CDN_DOMAIN_SUCCESS: 'GET_CDN_DOMAIN_SUCCESS',

  TOGGLE_SETUP_CDN_MODAL: 'TOGGLE_SETUP_CDN_MODAL',
  SETUP_CDN_START: 'SETUP_CDN_START',
  SETUP_CDN_SUCCESS: 'SETUP_CDN_SUCCESS',

  TOGGLE_ENABLE_CDN_MODAL: 'TOGGLE_ENABLE_CDN_MODAL',
  ENABLE_CDN_START: 'ENABLE_CDN_START',
  ENABLE_CDN_SUCCESS: 'ENABLE_CDN_SUCCESS',

  TOGGLE_ENABLE_CDN_DOMAIN_MODAL: 'cdn/TOGGLE_ENABLE_CDN_DOMAIN_MODAL',
  ENABLE_CDN_DOMAIN_START: 'cdn/ENABLE_CDN_DOMAIN_START',
  ENABLE_CDN_DOMAIN_SUCCESS: 'cdn/ENABLE_CDN_DOMAIN_SUCCESS',

  ADD_CUSTOM_CDN_START: 'ADD_CUSTOM_CDN_START',
  ADD_CUSTOM_CDN_SUCCESS: 'ADD_CUSTOM_CDN_SUCCESS',

  TOGGLE_DELETE_CUSTOM_CDN_MODAL: 'TOGGLE_DELETE_CUSTOM_CDN_MODAL',
  DELETE_CUSTOM_CDN_START: 'DELETE_CUSTOM_CDN_START',
  DELETE_CUSTOM_CDN_SUCCESS: 'DELETE_CUSTOM_CDN_SUCCESS',

  ENABLE_CREATE_NEW_CDN_START: 'ENABLE_CREATE_NEW_CDN_START',
  ENABLE_CREATE_NEW_CDN_SUCCESS: 'ENABLE_CREATE_NEW_CDN_SUCCESS',
};

const listCdnStart = (domainId) => ({
  type: types.LIST_CDN_START,
  data: { domainId },
});
const listCdnSuccess = (cdns) => ({
  type: types.LIST_CDN_SUCCESS,
  data: cdns,
});
const cdnOperationFailed = (data) => ({
  type: types.CDN_OPERATION_FAILED,
  data,
});

const listCdnProviderStart = () => ({ type: types.LIST_CDN_PROVIDER_START });
const listCdnProviderSuccess = (providers) => ({
  type: types.LIST_CDN_PROVIDER_SUCCESS,
  data: providers,
});

const setCdnItemList = (itemList) => ({
  type: types.SET_CDN_ITEM_LIST,
  data: itemList,
});

const getCdnDomainStart = (domainName) => ({
  type: types.GET_CDN_DOMAIN_START,
  data: domainName,
});

const getCdnDomainSuccess = (domain) => ({
  type: types.GET_CDN_DOMAIN_SUCCESS,
  data: domain,
});

const toggleSetupCdnModal = (data) => ({
  type: types.TOGGLE_SETUP_CDN_MODAL,
  data,
});
const setupCdnStart = (domain, cdnItem) => ({
  type: types.SETUP_CDN_START,
  data: { domain, data: cdnItem },
});
const setupCdnSuccess = (data) => ({
  type: types.SETUP_CDN_SUCCESS,
  data,
});

const toggleEnableCdnModal = (cdn) => ({
  type: types.TOGGLE_ENABLE_CDN_MODAL,
  data: cdn,
});

const enableCdnStart = (domain, cdn, toVal) => ({
  type: types.ENABLE_CDN_START,
  data: { domain, cdn, toVal },
});

const enableCdnSuccess = (data) => ({
  type: types.ENABLE_CDN_SUCCESS,
  data,
});

const toggleEnableCdnDomainModal = (domain) => ({
  type: types.TOGGLE_ENABLE_CDN_DOMAIN_MODAL,
  data: domain,
});

const enableCdnDomainStart = (domain, toVal) => ({
  type: types.ENABLE_CDN_DOMAIN_START,
  data: { domain, toVal },
});

const enableCdnDomainSuccess = (data) => ({
  type: types.ENABLE_CDN_DOMAIN_SUCCESS,
  data,
});

const addCustomCdnStart = (domainId, customCdn) => ({
  type: types.ADD_CUSTOM_CDN_START,
  data: { domainId, data: customCdn },
});

const addCustomCdnSuccess = (data) => ({
  type: types.ADD_CUSTOM_CDN_SUCCESS,
  data,
});

const toggleDeleteCustomCdnModal = (data) => ({
  type: types.TOGGLE_DELETE_CUSTOM_CDN_MODAL,
  data,
});

const deleteCustomCdnStart = (domain, cdnItem) => ({
  type: types.DELETE_CUSTOM_CDN_START,
  data: { domain, data: cdnItem },
});

const deleteCustomCdnSuccess = (data) => ({
  type: types.DELETE_CUSTOM_CDN_SUCCESS,
  data,
});

const enableCreateNewCdnStart = (domain, provider) => ({
  type: types.ENABLE_CREATE_NEW_CDN_START,
  data: { domain, provider },
});

const enableCreateNewCdnSuccess = (data) => ({
  type: types.ENABLE_CREATE_NEW_CDN_SUCCESS,
  data,
});

const listCdn = (domainId) => (dispatch, _getState, { axiosRequest }) => {
  dispatch(listCdnStart(domainId));
  const url = CDN_API_CDN.replace('{domainId}', domainId);
  return axiosRequest
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        dispatch(listCdnSuccess(response.data));
      }
    })
    .catch((error) => {
      dispatch(cdnOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const listCdnProvider = () => (dispatch, _getState, { axiosRequest }) => {
  dispatch(listCdnProviderStart());
  return axiosRequest
    .get(CDN_API_PROVIDERS)
    .then((response) => {
      if (response.status === 200) {
        dispatch(listCdnProviderSuccess(response.data));
      }
    })
    .catch((error) => {
      dispatch(cdnOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const getCdnDomain = (domainName) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  dispatch(getCdnDomainStart(domainName));
  return axiosRequest
    .get(CDN_API_DOMAIN, { params: { name: domainName } })
    .then((response) => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          dispatch(getCdnDomainSuccess(response.data[0]));
        } else {
          throw new Error('Domain not found');
        }
      }
    })
    .catch((error) => {
      dispatch(cdnOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const setupCdn = (domain, cdnItem) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  dispatch(setupCdnStart(domain, cdnItem));
  const url = CDN_API_CDN_ITEM.replace('{domainId}', domain.id).replace(
    '{cdnId}',
    cdnItem.id
  );
  return axiosRequest
    .put(url, cdnItem)
    .then((response) => {
      if (response.status === 202) {
        dispatch(setupCdnSuccess(response.data));
        dispatch(listCdn(domain.id));
      } else {
        toast.warn(
          `Request done with response code ${response.status}, expected 201`
        );
      }
    })
    .catch((error) => {
      dispatch(cdnOperationFailed(error));
      let message = 'There is an error while processing your request.';
      if (error.response) {
        if (error.response.status === 409 || error.response.status === 500) {
          message = error.response.data.error;
        } else if (error.response.status === 422) {
          message = error.response.data.errors;
        }
      }
      toast.error(message);
    });
};

const enableCdn = (domain, cdnItem, toVal) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  const url = CDN_API_CDN_STATUS.replace('{cdnId}', cdnItem.id)
    .replace('{status}', toVal)
    .replace('{domainId}', domain.id);
  dispatch(enableCdnStart(domain, cdnItem, toVal));

  return axiosRequest
    .put(url)
    .then((response) => {
      if (response.status === 202) {
        dispatch(enableCdnSuccess(response.data));
        dispatch(listCdn(domain.id));
      }
    })
    .catch((error) => {
      dispatch(cdnOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const enableCdnDomain = (domain, toVal) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  const url = CDN_API_DOMAIN_STATUS.replace('{domainId}', domain.id).replace(
    '{status}',
    toVal
  );
  dispatch(enableCdnDomainStart(domain, toVal));

  return axiosRequest
    .put(url)
    .then((response) => {
      if (response.status === 202) {
        dispatch(enableCdnDomainSuccess(response.data));
        dispatch(getCdnDomain(domain.name));
      }
    })
    .catch((error) => {
      dispatch(cdnOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const addCustomCdn = (domainId, customCdn) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  const url = CDN_API_CDN.replace('{domainId}', domainId);
  dispatch(addCustomCdnStart(domainId, customCdn));

  return axiosRequest
    .post(url, customCdn)
    .then((response) => {
      if (response.status === 201) {
        dispatch(addCustomCdnSuccess(response.data));
        dispatch(listCdn(domainId));
      }
    })
    .catch((error) => {
      dispatch(cdnOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const enableCreateNewCdn = (domain, provider) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  const data = { domain: domain.name, providers: [provider], is_enabled: true };
  dispatch(enableCreateNewCdnStart(domain, provider));
  const url = CDN_API_DOMAIN_CREATE;
  return axiosRequest
    .post(url, data)
    .then((response) => {
      if (response.status === 201) {
        dispatch(enableCreateNewCdnSuccess(response.data));
        dispatch(listCdn(domain.id));
      } else {
        const message = `Creation return with status ${response.status}, expected 201`;
        toast.error(message);
        dispatch(cdnOperationFailed(new Error(message)));
      }
    })
    .catch((error) => {
      dispatch(cdnOperationFailed(error));
      let message = 'There is an error while processing your request.';
      if (error.response) {
        if (error.response.status === 409 || error.response.status === 500) {
          message = error.response.data.error;
        } else if (error.response.status === 422) {
          message = error.response.data.errors;
        }
      }
      toast.error(message);
    });
};

const deleteCustomCdn = (domain, cdnItem) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  const url = CDN_API_CDN_ITEM.replace('{domainId}', domain.id).replace(
    '{cdnId}',
    cdnItem.id
  );
  dispatch(deleteCustomCdnStart(domain, cdnItem));

  return axiosRequest
    .delete(url)
    .then((response) => {
      if (response.status === 202) {
        dispatch(deleteCustomCdnSuccess(response.data));
        dispatch(listCdn(domain.id));
      }
    })
    .catch((error) => {
      dispatch(cdnOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

export default {
  listCdn,
  listCdnStart,
  listCdnSuccess,
  cdnOperationFailed,

  listCdnProvider,
  listCdnProviderStart,
  listCdnProviderSuccess,

  setCdnItemList,

  getCdnDomain,
  getCdnDomainStart,
  getCdnDomainSuccess,

  setupCdn,
  toggleSetupCdnModal,
  setupCdnStart,
  setupCdnSuccess,

  enableCdn,
  toggleEnableCdnModal,
  enableCdnStart,
  enableCdnSuccess,

  enableCdnDomain,
  toggleEnableCdnDomainModal,
  enableCdnDomainStart,
  enableCdnDomainSuccess,

  addCustomCdn,
  addCustomCdnStart,
  addCustomCdnSuccess,

  deleteCustomCdn,
  toggleDeleteCustomCdnModal,
  deleteCustomCdnStart,
  deleteCustomCdnSuccess,

  enableCreateNewCdn,
  enableCreateNewCdnStart,
  enableCreateNewCdnSuccess,
};
export { types };
