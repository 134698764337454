import { CSidebarNav, CSidebarNavItem } from '@coreui/react';
import React from 'react';
import { CDNAPP_BASE_URL, DNAPI_BASE_URL } from '../../routes/apiRoutes';
import { SHOW_DASHBOARD, SHOW_NODE } from '../../utils/constants';

const SidebarNav = () => {
  return (
    <CSidebarNav>
      {SHOW_DASHBOARD && (
        <CSidebarNavItem
          name="Dashboard"
          to="/dashboard"
          fontIcon="cil-speedometer"
          badge={{
            color: 'info',
            text: 'coming soon',
          }}
        />
      )}

      {SHOW_NODE && (
        <CSidebarNavItem
          name="Nodes"
          to="/nodes"
          fontIcon="cil-screen-desktop"
        />
      )}

      {DNAPI_BASE_URL && (
        <CSidebarNavItem name="DNS" to="/dns" fontIcon="cil-globe-alt" />
      )}

      {CDNAPP_BASE_URL && (
        <CSidebarNavItem name="CDN" to="/cdn" fontIcon="cil-vector" />
      )}
    </CSidebarNav>
  );
};

export default SidebarNav;
