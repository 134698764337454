import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { axiosRequest } from '../../utils/http';
import rootReducer from '../reducers';

const persistConfig = {
  key: 'root',
  whitelist: ['option'],
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk.withExtraArgument({ axiosRequest })];

export default function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    applyMiddleware(...middleware)
  );
  const persistor = persistStore(store);
  return { store, persistor };
}
