import { combineReducers } from 'redux';
import node from './nodeReducer';
import main from './mainReducer';
import domain from './domainReducer';
import subDomain from './subDomainReducer';
import rule from './ruleReducer';
import apiCallsInProgress from './apiStatusReducer';
import auth from './authReducer';
import option from './optionReducer';
import cdnDomain from './cdnDomainReducer';
import cdn from './cdnReducer';
import cdnRegion from './cdnRegionReducer';

const rootReducer = combineReducers({
  node,
  main,
  domain,
  subDomain,
  rule,
  apiCallsInProgress,
  auth,
  option,
  cdnDomain,
  cdn,
  cdnRegion,
});

export default rootReducer;
