import Cookies from 'js-cookie';
import * as types from '../actions/actionTypes';
import { COOKIE_USER_TOKEN } from '../../utils/constants';

// Prepare variables.
const initialState = {
  isAuthenticated: !!Cookies.get(COOKIE_USER_TOKEN),
  error: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case types.AUTH_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
