import { ACTIONS } from '../actions/ruleActions';

// Prepare variables.
const initialState = {
  listing: null,
  form: {
    type: '',
    content: '',
    ttl: '',
    weight: '',
    priority: '',
    region: '',
    country: '',
    is_enabled: true,
  },
  toDelete: null,
  isLoading: false,
  error: null,
  modal: {
    add: {
      status: false,
      error: null,
    },
    edit: {
      status: false,
      error: null,
    },
    delete: {
      status: false,
      error: null,
    },
    request_status: {
      status: false,
      error: null,
    },
  },
  selectedRule: null,
  toggleRequestStatus: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_RULE_LISTING:
      if (action.data !== null)
        return {
          ...state,
          listing: {
            ...state.listing,
            ...action.data,
          },
        };
      return {
        ...state,
        listing: null,
      };
    case ACTIONS.UPDATE_RULE_FORM:
      if (action.data !== null)
        return {
          ...state,
          form: {
            ...state.form,
            ...action.data,
          },
        };
      return {
        ...state,
        form: {
          ...initialState.form,
        },
      };
    case ACTIONS.LIST_RULE_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.LIST_RULE_SUCCESS:
      return {
        ...state,
        listing: {
          ...action.data,
        },
      };
    case ACTIONS.LIST_RULE_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.CREATE_RULE_FORM_OPEN:
      return {
        ...state,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            status: true,
          },
        },
        form: {
          ...state.form,
          is_enabled: true,
        },
      };
    case ACTIONS.CREATE_RULE_FORM_CLOSE:
      return {
        ...state,
        form: {
          ...initialState.form,
        },
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            status: false,
            error: null,
          },
        },
      };
    case ACTIONS.CREATE_RULE_FORM_INVALID:
      return {
        ...state,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            error: action.data,
          },
        },
      };
    case ACTIONS.CREATE_RULE_FORM_CLEAR_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            error: null,
          },
        },
      };
    case ACTIONS.CREATE_RULE_START:
      return {
        ...state,
        isLoading: true,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            error: null,
          },
        },
      };
    case ACTIONS.CREATE_RULE_FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            error: action.data,
          },
        },
      };
    case ACTIONS.CREATE_RULE_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.UPDATE_RULE_FORM_OPEN:
      return {
        ...state,
        form:
          action.data.form !== null
            ? { ...state.form, ...action.data.form }
            : {
                ...state.form,
                ...initialState.form,
              },
        modal: {
          ...state.modal,
          edit: {
            ...state.modal.edit,
            status: true,
          },
        },
        selectedRule: action.data.selectedRule,
      };
    case ACTIONS.UPDATE_RULE_FORM_CLOSE:
      return {
        ...state,
        form: {
          ...initialState.form,
        },
        modal: {
          ...state.modal,
          edit: {
            ...state.modal.edit,
            status: false,
            error: null,
          },
        },
        selectedRule: null,
      };
    case ACTIONS.UPDATE_RULE_FORM_INVALID:
      return {
        ...state,
        modal: {
          ...state.modal,
          edit: {
            ...state.modal.edit,
            error: action.data,
          },
        },
      };
    case ACTIONS.UPDATE_RULE_START:
      return {
        ...state,
        isLoading: true,
        modal: {
          ...state.modal,
          edit: {
            ...state.modal.edit,
            error: null,
          },
        },
      };
    case ACTIONS.UPDATE_RULE_FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          edit: {
            ...state.modal.edit,
            error: action.data,
          },
        },
      };
    case ACTIONS.UPDATE_RULE_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.DELETE_RULE_FORM_OPEN:
      return {
        ...state,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            status: true,
          },
        },
        toDelete: action.data,
      };
    case ACTIONS.DELETE_RULE_FORM_CLOSE:
      return {
        ...state,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            status: false,
            error: null,
          },
        },
        toDelete: null,
      };
    case ACTIONS.DELETE_RULE_START:
      return {
        ...state,
        isLoading: true,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            error: null,
          },
        },
      };
    case ACTIONS.DELETE_RULE_FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            error: action.data,
          },
        },
      };
    case ACTIONS.DELETE_RULE_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.TOGGLE_RULE_STATUS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.TOGGLE_RULE_STATUS_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.TOGGLE_RULES_REQUEST:
      return {
        ...state,
        toggleRequestStatus: action.data,
      };
    case ACTIONS.TOGGLE_RULES_STATUS_FORM_OPEN:
      return {
        ...state,
        modal: {
          ...state.modal,
          request_status: {
            ...state.modal.request_status,
            status: true,
          },
        },
      };
    case ACTIONS.TOGGLE_RULES_STATUS_FORM_CLOSE:
      return {
        ...state,
        modal: {
          ...state.modal,
          request_status: {
            ...state.modal.request_status,
            status: false,
            error: null,
          },
        },
      };
    case ACTIONS.TOGGLE_RULES_STATUS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.TOGGLE_RULES_STATUS_END:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
