import { toast } from 'react-toastify';
import { listSubDomainAndRule } from './subDomainActions';
import {
  DN_API_RULES,
  DN_API_UPDATE_RULE_STATUS,
  DN_API_UPDATE_DOMAIN_RULES_STATUS,
} from '../../routes/apiRoutes';

export const ACTIONS = {
  LIST_RULE_START: 'LIST_RULE_START',
  LIST_RULE_SUCCESS: 'LIST_RULE_SUCCESS',
  LIST_RULE_END: 'LIST_RULE_END',
  CREATE_RULE_FORM_OPEN: 'CREATE_RULE_FORM_OPEN',
  CREATE_RULE_FORM_CLOSE: 'CREATE_RULE_FORM_CLOSE',
  CREATE_RULE_FORM_INVALID: 'CREATE_RULE_FORM_INVALID',
  CREATE_RULE_START: 'CREATE_RULE_START',
  CREATE_RULE_FAIL: 'CREATE_RULE_FAIL',
  CREATE_RULE_END: 'CREATE_RULE_END',
  UPDATE_RULE_FORM_OPEN: 'UPDATE_RULE_FORM_OPEN',
  UPDATE_RULE_FORM_CLOSE: 'UPDATE_RULE_FORM_CLOSE',
  UPDATE_RULE_FORM_INVALID: 'UPDATE_RULE_FORM_INVALID',
  UPDATE_RULE_START: 'UPDATE_RULE_START',
  UPDATE_RULE_FAIL: 'UPDATE_RULE_FAIL',
  UPDATE_RULE_END: 'UPDATE_RULE_END',
  DELETE_RULE_FORM_OPEN: 'DELETE_RULE_FORM_OPEN',
  DELETE_RULE_FORM_CLOSE: 'DELETE_RULE_FORM_CLOSE',
  DELETE_RULE_START: 'DELETE_RULE_START',
  DELETE_RULE_FAIL: 'DELETE_RULE_FAIL',
  DELETE_RULE_END: 'DELETE_RULE_END',
  TOGGLE_RULES_REQUEST: 'TOGGLE_RULES_REQUEST',
  TOGGLE_RULES_STATUS_FORM_OPEN: 'TOGGLE_RULES_STATUS_FORM_OPEN',
  TOGGLE_RULES_STATUS_FORM_CLOSE: 'TOGGLE_RULES_STATUS_FORM_CLOSE',
  TOGGLE_RULE_STATUS_START: 'TOGGLE_RULE_STATUS_START',
  TOGGLE_RULE_STATUS_END: 'TOGGLE_RULE_STATUS_END',
  UPDATE_SUB_DOMAIN_SELECTED_SUB_DOMAIN:
    'UPDATE_SUB_DOMAIN_SELECTED_SUB_DOMAIN',
};

export const updateSubDomainSelectedSubDomain = (data) => ({
  type: ACTIONS.UPDATE_SUB_DOMAIN_SELECTED_SUB_DOMAIN,
  data,
});
export const listRuleStart = () => ({
  type: ACTIONS.LIST_RULE_START,
});
export const listRuleSuccess = (data) => ({
  type: ACTIONS.LIST_RULE_SUCCESS,
  data,
});
export const listRuleEnd = () => ({
  type: ACTIONS.LIST_RULE_END,
});
export const createRuleFormOpen = (subDomain) => {
  return (dispatch) => {
    dispatch(updateSubDomainSelectedSubDomain(subDomain));
    dispatch({
      type: ACTIONS.CREATE_RULE_FORM_OPEN,
    });
  };
};
export const createRuleFormClose = () => {
  return (dispatch) => {
    dispatch(updateSubDomainSelectedSubDomain(null));
    dispatch({ type: ACTIONS.CREATE_RULE_FORM_CLOSE });
  };
};
export const createRuleStart = () => ({
  type: ACTIONS.CREATE_RULE_START,
});
export const createRuleFail = (data) => ({
  type: ACTIONS.CREATE_RULE_FAIL,
  data,
});
export const createRuleEnd = () => ({
  type: ACTIONS.CREATE_RULE_END,
});
export const updateRuleFormOpen = (
  rule = null,
  ruleId = null,
  subDomain = null
) => {
  return (dispatch) => {
    dispatch(updateSubDomainSelectedSubDomain(subDomain));
    dispatch({
      type: ACTIONS.UPDATE_RULE_FORM_OPEN,
      data: {
        form: rule,
        selectedRule: ruleId,
      },
    });
  };
};
export const updateRuleFormClose = () => {
  return (dispatch) => {
    dispatch(updateSubDomainSelectedSubDomain(null));
    dispatch({ type: ACTIONS.UPDATE_RULE_FORM_CLOSE });
  };
};
export const updateRuleStart = () => ({
  type: ACTIONS.UPDATE_RULE_START,
});
export const updateRuleFail = (data) => ({
  type: ACTIONS.UPDATE_RULE_FAIL,
  data,
});
export const updateRuleEnd = () => ({
  type: ACTIONS.UPDATE_RULE_END,
});
export const deleteRuleFormOpen = (data) => ({
  type: ACTIONS.DELETE_RULE_FORM_OPEN,
  data,
});
export const deleteRuleFormClose = () => {
  return (dispatch) => {
    dispatch(updateSubDomainSelectedSubDomain(null));
    dispatch({ type: ACTIONS.DELETE_RULE_FORM_CLOSE });
  };
};
export const deleteRuleStart = () => ({
  type: ACTIONS.DELETE_RULE_START,
});
export const deleteRuleFail = (data) => ({
  type: ACTIONS.DELETE_RULE_FAIL,
  data,
});
export const deleteRuleEnd = () => ({
  type: ACTIONS.DELETE_RULE_END,
});
export const toggleRuleStatusStart = () => ({
  type: ACTIONS.TOGGLE_RULE_STATUS_START,
});
export const toggleRuleStatusEnd = () => ({
  type: ACTIONS.TOGGLE_RULE_STATUS_END,
});
export const toggleRulesRequest = (data) => ({
  type: ACTIONS.TOGGLE_RULES_REQUEST,
  data,
});
export const toggleRulesStatusFormOpen = (data) => ({
  type: ACTIONS.TOGGLE_RULES_STATUS_FORM_OPEN,
  data,
});
export const toggleRulesStatusFormClose = (data) => ({
  type: ACTIONS.TOGGLE_RULES_STATUS_FORM_CLOSE,
  data,
});
export const toggleRulesStatusStart = () => ({
  type: ACTIONS.TOGGLE_RULE_STATUS_START,
});
export const toggleRulesStatusEnd = () => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.TOGGLE_RULE_STATUS_END });
  };
};

// Action creators using Thunk middleware.
export const listRuleBySubDomain = (domainName, subDomain) => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(listRuleStart());

    return axiosRequest
      .get(
        `${DN_API_RULES}?domain_name=${domainName}&sub_domain_name=${subDomain.name}`
      )
      .then((response) => {
        if (response.status === 200 && response.data !== null) {
          dispatch(
            listRuleSuccess({
              [subDomain.name]: response.data,
            })
          );
        } else {
          toast.error('There is an error while processing your request.');
        }
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        dispatch(listRuleEnd());
      });
  };
};
export const createRule = (domainName, subDomainName, payload) => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(createRuleStart());

    return axiosRequest
      .post(
        `${DN_API_RULES}?domain_name=${domainName}&sub_domain_name=${subDomainName}`,
        payload
      )
      .then((response) => {
        if (response.status && response.status === 201) {
          dispatch(createRuleFormClose());
          dispatch(listSubDomainAndRule(domainName));
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (error.response.status === 409 || error.response.status === 500) {
          errorMessage = error.response.data.error;
        } else if (error.response.status === 422) {
          errorMessage = error.response.data.errors;
        }
        dispatch(createRuleFail(errorMessage));
      })
      .finally(() => {
        dispatch(createRuleEnd());
      });
  };
};
export const updateRule = (domainName, ruleId, payload) => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(updateRuleStart());

    return axiosRequest
      .put(`${DN_API_RULES}/${ruleId}`, payload)
      .then((response) => {
        if (response.status && response.status === 202) {
          dispatch(updateRuleFormClose());
          dispatch(listSubDomainAndRule(domainName));
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (error.response.status === 409 || error.response.status === 500) {
          errorMessage = error.response.data.error;
        } else if (error.response.status === 422) {
          errorMessage = error.response.data.errors;
        }
        dispatch(updateRuleFail(errorMessage));
      })
      .finally(() => {
        dispatch(updateRuleEnd());
      });
  };
};
export const deleteRule = (domainName) => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(deleteRuleStart());

    return axiosRequest
      .delete(`${DN_API_RULES}/${getState().rule.toDelete.id}`)
      .then((response) => {
        if (response.status && response.status === 202) {
          dispatch(deleteRuleFormClose());
          dispatch(listSubDomainAndRule(domainName));
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (error.response.status === 500) {
          errorMessage = error.response.data.error;
        }
        dispatch(deleteRuleFail(errorMessage));
      })
      .finally(() => {
        dispatch(deleteRuleEnd());
      });
  };
};
export const toggleRuleStatus = (rule, domainName) => {
  const newStatus = !rule.is_enabled;

  return (dispatch, getState, { axiosRequest }) => {
    dispatch(toggleRuleStatusStart());

    return axiosRequest
      .put(
        DN_API_UPDATE_RULE_STATUS.replace('{ruleId}', rule.id).replace(
          '{status}',
          newStatus.toString()
        )
      )
      .then((response) => {
        if (response.status && response.status === 202) {
          dispatch(listSubDomainAndRule(domainName));
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (error.response.status === 500) {
          errorMessage = error.response.data.error;
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        dispatch(toggleRuleStatusEnd());
      });
  };
};
export const toggleRulesStatus = (toggleRequest, domainName) => {
  const newStatus = toggleRequest;

  return (dispatch, getState, { axiosRequest }) => {
    dispatch(toggleRulesStatusStart());

    return axiosRequest
      .put(
        `${DN_API_UPDATE_DOMAIN_RULES_STATUS.replace(
          '{status}',
          newStatus
        )}?domain_name=${domainName}`
      )
      .then((response) => {
        if (response.status && response.status === 202) {
          dispatch(listSubDomainAndRule(domainName));
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (error.response.status === 500) {
          errorMessage = error.response.data.error;
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        dispatch(toggleRulesStatusEnd());
        dispatch(toggleRulesStatusFormClose());
      });
  };
};
