import React from 'react';

const Nodes = React.lazy(() => import('../../components/Nodes/NodesPage'));
const ManageNodePage = React.lazy(() =>
  import('../../components/Nodes/ManageNodePage')
);
const Domains = React.lazy(() => import('../../components/Domain'));
// const SubDomains = React.lazy(() => import('../../components/SubDomain'));
const Rules = React.lazy(() => import('../../components/Rule'));
const CDNDomains = React.lazy(() => import('../../components/CDN'));
const CDNs = React.lazy(() => import('../../components/CDN/CdnList'));

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/nodes', exact: true, name: 'Nodes', component: Nodes },
  {
    path: '/nodes/new',
    exact: true,
    name: 'Add Node',
    component: ManageNodePage,
  },
  {
    path: '/nodes/:slug',
    exact: true,
    name: 'Edit Node',
    component: ManageNodePage,
  },
  { path: '/dns', exact: true, name: 'DNS', component: Domains },
  {
    path: '/dns/:domainName',
    exact: true,
    name: 'Rules',
    component: Rules,
  },
  { path: '/cdn', exact: true, name: 'CDN Domains', component: CDNDomains },
  {
    path: '/cdn/:domainName',
    exact: true,
    name: 'CDN List',
    component: CDNs,
  },
];

export default routes;
