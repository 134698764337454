import { toast } from 'react-toastify';
import {
  DN_API_SUB_DOMAINS,
  DN_API_GET_RULES_BY_DOMAIN,
} from '../../routes/apiRoutes';

export const ACTIONS = {
  LIST_RULE_SUCCESS: 'LIST_RULE_SUCCESS',
  UPDATE_RULE_LISTING: 'UPDATE_RULE_LISTING',
  UPDATE_SUB_DOMAIN_FORM: 'UPDATE_SUB_DOMAIN_FORM',
  UPDATE_SUB_DOMAIN_SELECTED_SUB_DOMAIN:
    'UPDATE_SUB_DOMAIN_SELECTED_SUB_DOMAIN',
  LIST_SUB_DOMAIN_START: 'LIST_SUB_DOMAIN_START',
  LIST_SUB_DOMAIN_SUCCESS: 'LIST_SUB_DOMAIN_SUCCESS',
  LIST_SUB_DOMAIN_END: 'LIST_SUB_DOMAIN_END',
  CREATE_SUB_DOMAIN_FORM_OPEN: 'CREATE_SUB_DOMAIN_FORM_OPEN',
  CREATE_SUB_DOMAIN_FORM_CLOSE: 'CREATE_SUB_DOMAIN_FORM_CLOSE',
  CREATE_SUB_DOMAIN_FORM_INVALID: 'CREATE_SUB_DOMAIN_FORM_INVALID',
  CREATE_SUB_DOMAIN_START: 'CREATE_SUB_DOMAIN_START',
  CREATE_SUB_DOMAIN_FAIL: 'CREATE_SUB_DOMAIN_FAIL',
  CREATE_SUB_DOMAIN_END: 'CREATE_SUB_DOMAIN_END',
  DELETE_SUB_DOMAIN_FORM_OPEN: 'DELETE_SUB_DOMAIN_FORM_OPEN',
  DELETE_SUB_DOMAIN_FORM_CLOSE: 'DELETE_SUB_DOMAIN_FORM_CLOSE',
  DELETE_SUB_DOMAIN_START: 'DELETE_SUB_DOMAIN_START',
  DELETE_SUB_DOMAIN_FAIL: 'DELETE_SUB_DOMAIN_FAIL',
  DELETE_SUB_DOMAIN_END: 'DELETE_SUB_DOMAIN_END',
  TOGGLE_SUBDOMAIN_EXPAND: 'TOGGLE_SUBDOMAIN_EXPAND',
  RESET_SUBDOMAIN_EXPAND: 'RESET_SUBDOMAIN_EXPAND',
};

export const listRuleSuccess = (data) => ({
  type: ACTIONS.LIST_RULE_SUCCESS,
  data,
});
export const updateRuleListing = (data) => ({
  type: ACTIONS.UPDATE_RULE_LISTING,
  data,
});
export const listSubDomainStart = () => ({
  type: ACTIONS.LIST_SUB_DOMAIN_START,
});
export const listSubDomainSuccess = (data) => ({
  type: ACTIONS.LIST_SUB_DOMAIN_SUCCESS,
  data,
});
export const listSubDomainEnd = () => ({
  type: ACTIONS.LIST_SUB_DOMAIN_END,
});
export const createSubDomainFormOpen = () => ({
  type: ACTIONS.CREATE_SUB_DOMAIN_FORM_OPEN,
});
export const createSubDomainFormClose = () => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.CREATE_SUB_DOMAIN_FORM_CLOSE });
  };
};
export const createSubDomainStart = () => ({
  type: ACTIONS.CREATE_SUB_DOMAIN_START,
});
export const createSubDomainFail = (data) => ({
  type: ACTIONS.CREATE_SUB_DOMAIN_FAIL,
  data,
});
export const createSubDomainEnd = () => ({
  type: ACTIONS.CREATE_SUB_DOMAIN_END,
});
export const deleteSubDomainFormOpen = (data) => ({
  type: ACTIONS.DELETE_SUB_DOMAIN_FORM_OPEN,
  data,
});
export const deleteSubDomainFormClose = () => ({
  type: ACTIONS.DELETE_SUB_DOMAIN_FORM_CLOSE,
});
export const deleteSubDomainStart = () => ({
  type: ACTIONS.DELETE_SUB_DOMAIN_START,
});
export const deleteSubDomainFail = (data) => ({
  type: ACTIONS.DELETE_SUB_DOMAIN_FAIL,
  data,
});
export const deleteSubDomainEnd = () => ({
  type: ACTIONS.DELETE_SUB_DOMAIN_END,
});

export const toggleSubdomainExpand = (domain, subdomain) => ({
  type: ACTIONS.TOGGLE_SUBDOMAIN_EXPAND,
  data: { domain, subdomain },
});

export const resetSubdomainExpand = (domain) => ({
  type: ACTIONS.RESET_SUBDOMAIN_EXPAND,
  data: domain,
});

// Action creators using Thunk middleware.
export const listSubDomainAndRule = (domainName) => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(listSubDomainStart());

    return axiosRequest
      .get(`${DN_API_GET_RULES_BY_DOMAIN}?domain_name=${domainName}`)
      .then((response) => {
        if (response.status === 200 && response.data !== null) {
          if (response.data.length > 0) {
            const subDomains = [];
            const rules = [];
            response.data.map((value) => {
              subDomains.push(value.subdomain);
              rules[value.subdomain.name] = value.rules;

              return null;
            });
            dispatch(listSubDomainSuccess(subDomains));
            dispatch(listRuleSuccess(rules));
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        dispatch(listSubDomainEnd());
      });
  };
};
export const createSubDomain = (domainName, payload) => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(createSubDomainStart());

    return axiosRequest
      .post(`${DN_API_SUB_DOMAINS}?domain_name=${domainName}`, payload)
      .then((response) => {
        if (response.status === 201) {
          dispatch(createSubDomainFormClose());
          dispatch(listSubDomainAndRule(domainName));
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (error.response.status === 409 || error.response.status === 500) {
          errorMessage = error.response.data.error;
        } else if (error.response.status === 422) {
          errorMessage = error.response.data.errors;
        }
        dispatch(createSubDomainFail(errorMessage));
      })
      .finally(() => {
        dispatch(createSubDomainEnd());
      });
  };
};
export const deleteSubDomain = (domainName) => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(deleteSubDomainStart());

    return axiosRequest
      .delete(
        `${DN_API_SUB_DOMAINS}?domain_name=${domainName}&sub_domain_name=${
          getState().subDomain.toDelete.name
        }`
      )
      .then((response) => {
        if (response.status === 202) {
          dispatch(deleteSubDomainFormClose());
          dispatch(listSubDomainAndRule(domainName));
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (error.response.status === 500) {
          errorMessage = error.response.data.error;
        }
        dispatch(deleteSubDomainFail(errorMessage));
      })
      .finally(() => {
        dispatch(deleteSubDomainEnd());
      });
  };
};
