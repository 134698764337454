import { ACTIONS } from '../actions/optionActions';

// Prepare variables.
const initialState = {
  countryRegion: [],
  ruleTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPTION_COUNTRY_REGION:
      return {
        ...state,
        countryRegion: action.data,
      };
    case ACTIONS.SET_OPTION_RULE_TYPE:
      return {
        ...state,
        ruleTypes: action.data,
      };
    default:
      return state;
  }
};
