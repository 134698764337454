import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SecuredRoute } from './containers';

// Import CSS.
import './App.scss';

// Import CoreUi javascript.
import '@coreui/coreui/dist/js/coreui.bundle.min';

toast.configure();

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const LoginPage = React.lazy(() => import('./components/Auth/Login'));
const RegisterPage = React.lazy(() => import('./components/Auth/Login'));
const Page404 = React.lazy(() => import('./components/Common/PageNotFound'));
const Page500 = React.lazy(() => import('./components/Common/PageNotFound'));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            // eslint-disable-next-line react/prop-types
            render={(props) => <LoginPage history={props.history} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            // eslint-disable-next-line react/prop-types
            render={(props) => <RegisterPage history={props.history} />}
          />
          <Route exact path="/404" name="Page 404" render={() => <Page404 />} />
          <Route exact path="/500" name="Page 500" render={() => <Page500 />} />
          <SecuredRoute
            path="/"
            name="Home"
            // eslint-disable-next-line react/prop-types
            render={(props) => <DefaultLayout history={props.history} />}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
