import * as types from '../actions/actionTypes';

const initialState = {
  nodes: [],
  node: {},
  error: null,
  totalCount: 0,
  apiCallsInProgress: 0,
};

export default function nodeReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.LOAD_NODES_SUCCESS:
      return { ...state, nodes: payload };
    case types.LOAD_NODE_SUCCESS:
      return { ...state, node: payload };
    case types.DELETE_NODE_OPTIMISTIC:
      const new_data = state.nodes.filter(
        (node) => node.ip_v4 !== action.payload.ip_v4
      );
      return { ...state, nodes: new_data };
    case types.LOAD_NODE_COUNT_SUCCESS:
      return { ...state, totalCount: parseInt(payload.total_count, 10) };
    default:
      return state;
  }
}
