import { ACTIONS } from '../actions/domainActions';

// Prepare variables.
const initialState = {
  listing: [],
  form: {
    name: '',
    description: '',
  },
  toDelete: null,
  toToggleRules: null,
  isLoading: false,
  error: null,
  modal: {
    add: {
      status: false,
      error: null,
    },
    edit: {
      status: false,
      error: null,
    },
    delete: {
      status: false,
      error: null,
    },
    toggle: {
      status: false,
      error: null,
    },
  },
  selectedDomain: null,
  totalCount: 0,
};

export default (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case ACTIONS.UPDATE_DOMAIN_FORM:
      if (data !== null)
        return {
          ...state,
          form: {
            ...state.form,
            ...data,
          },
        };
      return {
        ...state,
        form: {
          name: initialState.form.name,
          description: initialState.form.description,
        },
      };
    case ACTIONS.LIST_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.LIST_DOMAIN_SUCCESS:
      return {
        ...state,
        listing: data,
      };
    case ACTIONS.LIST_DOMAIN_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.CREATE_DOMAIN_FORM_OPEN:
      return {
        ...state,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            status: true,
          },
        },
      };
    case ACTIONS.CREATE_DOMAIN_FORM_CLOSE:
      return {
        ...state,
        form: {
          name: initialState.form.name,
          description: initialState.form.description,
        },
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            status: false,
            error: null,
          },
        },
      };
    case ACTIONS.CREATE_DOMAIN_FORM_INVALID:
      return {
        ...state,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            error: data,
          },
        },
      };
    case ACTIONS.CREATE_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            error: null,
          },
        },
      };
    case ACTIONS.CREATE_DOMAIN_FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            error: data,
          },
        },
      };
    case ACTIONS.CREATE_DOMAIN_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.UPDATE_DOMAIN_FORM_OPEN:
      return {
        ...state,
        form: {
          ...state.form,
          ...data,
        },
        modal: {
          ...state.modal,
          edit: {
            ...state.modal.edit,
            status: true,
          },
        },
        selectedDomain: data,
      };
    case ACTIONS.UPDATE_DOMAIN_FORM_CLOSE:
      return {
        ...state,
        form: {
          name: initialState.form.name,
          description: initialState.form.description,
        },
        modal: {
          ...state.modal,
          edit: {
            ...state.modal.edit,
            status: false,
            error: null,
          },
        },
        selectedDomain: null,
      };
    case ACTIONS.UPDATE_DOMAIN_FORM_INVALID:
      return {
        ...state,
        modal: {
          ...state.modal,
          edit: {
            ...state.modal.edit,
            error: data,
          },
        },
      };
    case ACTIONS.UPDATE_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
        modal: {
          ...state.modal,
          edit: {
            ...state.modal.edit,
            error: null,
          },
        },
      };
    case ACTIONS.UPDATE_DOMAIN_FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          edit: {
            ...state.modal.edit,
            error: data,
          },
        },
      };
    case ACTIONS.UPDATE_DOMAIN_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.DELETE_DOMAIN_FORM_OPEN:
      return {
        ...state,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            status: true,
          },
        },
        toDelete: data,
      };
    case ACTIONS.DELETE_DOMAIN_FORM_CLOSE:
      return {
        ...state,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            status: false,
            error: null,
          },
        },
        toDelete: null,
      };
    case ACTIONS.DELETE_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            error: null,
          },
        },
      };
    case ACTIONS.DELETE_DOMAIN_FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            error: data,
          },
        },
      };
    case ACTIONS.DELETE_DOMAIN_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.LOAD_DOMAIN_COUNT_SUCCESS:
      return { ...state, totalCount: parseInt(data.total_count, 10) };
    case ACTIONS.TOGGLE_DOMAIN_RULES_STATUS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.TOGGLE_DOMAIN_RULES_STATUS_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.TOGGLE_DOMAIN_RULES_FORM_OPEN:
      return {
        ...state,
        toToggleRules: data,
        modal: {
          ...state.modal,
          toggle: {
            status: true,
            error: null,
          },
        },
      };
    case ACTIONS.TOGGLE_DOMAIN_RULES_FORM_CLOSE:
      return {
        ...state,
        toToggleRules: null,
        modal: {
          ...state.modal,
          toggle: {
            status: false,
            error: null,
          },
        },
      };
    default:
      return state;
  }
};
