import { ACTIONS } from '../actions/subDomainActions';

const sortSubdomains = (subdomains) => {
  if (!Array.isArray(subdomains)) return subdomains;
  const root = subdomains.find((subdomain) => subdomain.name === '@');
  const others = subdomains.filter((subdomain) => subdomain.name !== '@');
  return [root, ...others.sort((a, b) => a.name.localeCompare(b.name))];
};

// Prepare variables.
const initialState = {
  listing: null,
  form: {
    name: '',
    description: '',
  },
  toDelete: null,
  isLoading: false,
  error: null,
  modal: {
    add: {
      status: false,
      error: null,
    },
    edit: {
      status: false,
      error: null,
    },
    delete: {
      status: false,
      error: null,
    },
  },
  selected_sub_domain: null,
  expanded: {
    domain: '',
    value: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_SUB_DOMAIN_FORM:
      if (action.data !== null)
        return {
          ...state,
          form: {
            ...state.form,
            ...action.data,
          },
        };
      return {
        ...state,
        form: {
          name: initialState.form.name,
          description: initialState.form.description,
        },
      };
    case ACTIONS.UPDATE_SUB_DOMAIN_SELECTED_SUB_DOMAIN:
      return {
        ...state,
        selected_sub_domain: action.data,
      };
    case ACTIONS.LIST_SUB_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.LIST_SUB_DOMAIN_SUCCESS:
      return {
        ...state,
        listing: sortSubdomains(action.data),
      };
    case ACTIONS.LIST_SUB_DOMAIN_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.CREATE_SUB_DOMAIN_FORM_OPEN:
      return {
        ...state,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            status: true,
          },
        },
      };
    case ACTIONS.CREATE_SUB_DOMAIN_FORM_CLOSE:
      return {
        ...state,
        form: {
          name: initialState.form.name,
          description: initialState.form.description,
        },
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            status: false,
            error: null,
          },
        },
      };
    case ACTIONS.CREATE_SUB_DOMAIN_FORM_INVALID:
      return {
        ...state,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            error: action.data,
          },
        },
      };
    case ACTIONS.CREATE_SUB_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            error: null,
          },
        },
      };
    case ACTIONS.CREATE_SUB_DOMAIN_FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          add: {
            ...state.modal.add,
            error: action.data,
          },
        },
      };
    case ACTIONS.CREATE_SUB_DOMAIN_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.DELETE_SUB_DOMAIN_FORM_OPEN:
      return {
        ...state,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            status: true,
          },
        },
        toDelete: action.data,
      };
    case ACTIONS.DELETE_SUB_DOMAIN_FORM_CLOSE:
      return {
        ...state,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            status: false,
            error: null,
          },
        },
        toDelete: null,
      };
    case ACTIONS.DELETE_SUB_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            error: null,
          },
        },
      };
    case ACTIONS.DELETE_SUB_DOMAIN_FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          delete: {
            ...state.modal.delete,
            error: action.data,
          },
        },
      };
    case ACTIONS.DELETE_SUB_DOMAIN_END:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS.TOGGLE_SUBDOMAIN_EXPAND:
      return {
        ...state,
        expanded: {
          domain: action.data.domain,
          value: {
            ...state.expanded.value,
            [action.data.subdomain]: !state.expanded.value[
              action.data.subdomain
            ],
          },
        },
      };
    case ACTIONS.RESET_SUBDOMAIN_EXPAND:
      return {
        ...state,
        expanded: {
          domain: action.data,
          value: {},
        },
      };
    default:
      return state;
  }
};
