export const types = {
  UPDATE_STATE: 'UPDATE_STATE',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_NODE_ITEMS: 'UPDATE_NODE_ITEMS',
  UPDATE_SHOW_PER_PAGE: 'UPDATE_SHOW_PER_PAGE',
};

export const updateState = (data) => ({
  type: types.UPDATE_STATE,
  data,
});

export const updateSettings = (data) => ({
  type: types.UPDATE_SETTINGS,
  data,
});

export const updateNodeItems = (data) => ({
  type: types.UPDATE_NODE_ITEMS,
  data,
});

export const updateShowPerPage = (data) => ({
  type: types.UPDATE_SHOW_PER_PAGE,
  data,
});
