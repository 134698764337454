import axios from 'axios';
import Cookies from 'js-cookie';
import { COOKIE_USER_TOKEN } from './constants';

// Prepare axios.
const axiosHttp = axios.create({});
axiosHttp.interceptors.request.use(
  (config) => {
    const token = Cookies.get(COOKIE_USER_TOKEN);
    const newConfig = config;

    if (token != null) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const http = (type, url, data = null) => {
  // Prepare configurations.
  const config = {
    method: type,
    url,
    data,
  };

  return axiosHttp(config)
    .then((response) => response)
    .catch((error) => error);
};

const axiosRequest = axios.create({});
axiosRequest.interceptors.request.use(
  (config) => {
    const token = Cookies.get(COOKIE_USER_TOKEN);
    const newConfig = config;

    if (token != null) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  },
  (err) => {
    return Promise.reject(err);
  }
);
axiosRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove(COOKIE_USER_TOKEN);
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export { http, axiosRequest };
