import { types } from '../actions/cdnActions';

// Prepare variables.
const initialState = {
  domain: null,
  providers: [],
  cdns: [],
  itemList: [],
  selectedItem: null,
  isLoading: false,
  error: '',
  isModalOpen: {
    setup: false,
    enable: false,
    enableDomain: false,
    deleteCustomCdn: false,
  },
  totalCount: 0,
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case types.LIST_CDN_PROVIDER_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.LIST_CDN_PROVIDER_SUCCESS:
      return {
        ...state,
        providers: data,
        isLoading: false,
      };
    case types.LIST_CDN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.LIST_CDN_SUCCESS:
      return {
        ...state,
        cdns: data,
        isLoading: false,
      };
    case types.CDN_OPERATION_FAILED:
      return {
        ...state,
        error: data,
        isLoading: false,
      };
    case types.SET_CDN_ITEM_LIST:
      return {
        ...state,
        itemList: data,
      };
    case types.GET_CDN_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_CDN_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        domain: data,
      };
    case types.TOGGLE_SETUP_CDN_MODAL:
      return {
        ...state,
        selectedItem: data,
        isModalOpen: {
          setup: !state.isModalOpen.setup,
          enable: false,
          enableDomain: false,
          deleteCustomCdn: false,
        },
      };
    case types.SETUP_CDN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.SETUP_CDN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          setup: false,
          enable: false,
          enableDomain: false,
          deleteCustomCdn: false,
        },
      };

    case types.TOGGLE_ENABLE_CDN_MODAL:
      return {
        ...state,
        selectedItem: data,
        isModalOpen: {
          setup: false,
          enable: !state.isModalOpen.enable,
          enableDomain: false,
          deleteCustomCdn: false,
        },
      };
    case types.ENABLE_CDN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ENABLE_CDN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          setup: false,
          enable: false,
          enableDomain: false,
          deleteCustomCdn: false,
        },
      };
    case types.ENABLE_CREATE_NEW_CDN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ENABLE_CREATE_NEW_CDN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          setup: false,
          enable: false,
          enableDomain: false,
          deleteCustomCdn: false,
        },
      };
    case types.TOGGLE_ENABLE_CDN_DOMAIN_MODAL:
      return {
        ...state,
        isModalOpen: {
          setup: false,
          enableDomain: !state.isModalOpen.enableDomain,
          enable: false,
          deleteCustomCdn: false,
        },
      };
    case types.ENABLE_CDN_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ENABLE_CDN_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          setup: false,
          enableDomain: false,
          enable: false,
          deleteCustomCdn: false,
        },
      };
    case types.ADD_CUSTOM_CDN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_CUSTOM_CDN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          setup: false,
          enableDomain: false,
          enable: false,
          deleteCustomCdn: false,
        },
      };
    case types.TOGGLE_DELETE_CUSTOM_CDN_MODAL:
      return {
        ...state,
        selectedItem: data,
        isModalOpen: {
          setUp: false,
          enableDomain: false,
          enable: false,
          deleteCustomCdn: !state.isModalOpen.deleteCustomCdn,
        },
      };
    case types.DELETE_CUSTOM_CDN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.DELETE_CUSTOM_CDN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          setUp: false,
          enableDomain: false,
          enable: false,
          deleteCustomCdn: false,
        },
      };
    default:
      return state;
  }
};
