export const LOAD_NODE_COUNT_SUCCESS = 'LOAD_NODE_COUNT_SUCCESS';
export const LOAD_NODE_SUCCESS = 'LOAD_NODE_SUCCESS';
export const LOAD_NODES_SUCCESS = 'LOAD_NODES_SUCCESS';
export const CREATE_NODE_SUCCESS = 'CREATE_NODE_SUCCESS';
export const UPDATE_NODE_SUCCESS = 'UPDATE_NODE_SUCCESS';
export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';

// By convention, actions that end in "_SUCCESS" are assumed to have been the result of a completed
// API call. But since we're doing an optimistic delete, we're hiding loading state.
// So this action name deliberately omits the "_SUCCESS" suffix.
// If it had one, our apiCallsInProgress counter would be decremented below zero
// because we're not incrementing the number of apiCallInProgress when the delete request begins.
export const DELETE_NODE_OPTIMISTIC = 'DELETE_NODE_OPTIMISTIC';

// Auth
export const AUTH_ERRORS = 'AUTH_ERRORS';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
