import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import {
  DN_API_DOMAINS,
  DN_API_DOMAIN_COUNT,
  DN_API_UPDATE_DOMAIN_RULES_STATUS,
} from '../../routes/apiRoutes';
import { apiCallError } from './apiStatusActions';
import { axiosRequest as AxiosRequest } from '../../utils/http';
import { DEFAULT_SHOW_PER_PAGE } from '../../utils/constants';

export const ACTIONS = {
  UPDATE_DOMAIN_FORM: 'UPDATE_DOMAIN_FORM',
  LIST_DOMAIN_START: 'LIST_DOMAIN_START',
  LIST_DOMAIN_SUCCESS: 'LIST_DOMAIN_SUCCESS',
  LIST_DOMAIN_END: 'LIST_DOMAIN_END',
  CREATE_DOMAIN_FORM_OPEN: 'CREATE_DOMAIN_FORM_OPEN',
  CREATE_DOMAIN_FORM_CLOSE: 'CREATE_DOMAIN_FORM_CLOSE',
  CREATE_DOMAIN_FORM_INVALID: 'CREATE_DOMAIN_FORM_INVALID',
  CREATE_DOMAIN_START: 'CREATE_DOMAIN_START',
  CREATE_DOMAIN_FAIL: 'CREATE_DOMAIN_FAIL',
  CREATE_DOMAIN_END: 'CREATE_DOMAIN_END',
  UPDATE_DOMAIN_FORM_OPEN: 'UPDATE_DOMAIN_FORM_OPEN',
  UPDATE_DOMAIN_FORM_CLOSE: 'UPDATE_DOMAIN_FORM_CLOSE',
  UPDATE_DOMAIN_FORM_INVALID: 'UPDATE_DOMAIN_FORM_INVALID',
  UPDATE_DOMAIN_START: 'UPDATE_DOMAIN_START',
  UPDATE_DOMAIN_FAIL: 'UPDATE_DOMAIN_FAIL',
  UPDATE_DOMAIN_END: 'UPDATE_DOMAIN_END',
  DELETE_DOMAIN_FORM_OPEN: 'DELETE_DOMAIN_FORM_OPEN',
  DELETE_DOMAIN_FORM_CLOSE: 'DELETE_DOMAIN_FORM_CLOSE',
  DELETE_DOMAIN_START: 'DELETE_DOMAIN_START',
  DELETE_DOMAIN_FAIL: 'DELETE_DOMAIN_FAIL',
  DELETE_DOMAIN_END: 'DELETE_DOMAIN_END',
  LOAD_DOMAIN_COUNT_SUCCESS: 'LOAD_DOMAIN_COUNT_SUCCESS',

  TOGGLE_DOMAIN_RULES_FORM_OPEN: 'TOGGLE_DOMAIN_RULES_FORM_OPEN',
  TOGGLE_DOMAIN_RULES_FORM_CLOSE: 'TOGGLE_DOMAIN_RULES_FORM_CLOSE',
  TOGGLE_DOMAIN_RULES_STATUS_START: 'TOGGLE_DOMAIN_RULES_STATUS_START',
  TOGGLE_DOMAIN_RULES_STATUS_END: 'TOGGLE_DOMAIN_RULES_STATUS_END',
};

export const updateDomainForm = (data) => ({
  type: ACTIONS.UPDATE_DOMAIN_FORM,
  data,
});
export const listDomainStart = () => ({
  type: ACTIONS.LIST_DOMAIN_START,
});
export const listDomainSuccess = (data) => ({
  type: ACTIONS.LIST_DOMAIN_SUCCESS,
  data,
});
export const listDomainEnd = () => ({
  type: ACTIONS.LIST_DOMAIN_END,
});
export const createDomainFormOpen = () => ({
  type: ACTIONS.CREATE_DOMAIN_FORM_OPEN,
});
export const createDomainFormClose = () => ({
  type: ACTIONS.CREATE_DOMAIN_FORM_CLOSE,
});
export const createDomainFormInvalid = (data) => ({
  type: ACTIONS.CREATE_DOMAIN_FORM_INVALID,
  data,
});
export const createDomainStart = () => ({
  type: ACTIONS.CREATE_DOMAIN_START,
});
export const createDomainFail = (data) => ({
  type: ACTIONS.CREATE_DOMAIN_FAIL,
  data,
});
export const createDomainEnd = () => ({
  type: ACTIONS.CREATE_DOMAIN_END,
});
export const updateDomainFormOpen = (data) => ({
  type: ACTIONS.UPDATE_DOMAIN_FORM_OPEN,
  data,
});
export const updateDomainFormClose = () => ({
  type: ACTIONS.UPDATE_DOMAIN_FORM_CLOSE,
});
export const updateDomainFormInvalid = (data) => ({
  type: ACTIONS.UPDATE_DOMAIN_FORM_INVALID,
  data,
});
export const updateDomainStart = () => ({
  type: ACTIONS.UPDATE_DOMAIN_START,
});
export const updateDomainFail = (data) => ({
  type: ACTIONS.UPDATE_DOMAIN_FAIL,
  data,
});
export const updateDomainEnd = () => ({
  type: ACTIONS.UPDATE_DOMAIN_END,
});
export const deleteDomainFormOpen = (data) => ({
  type: ACTIONS.DELETE_DOMAIN_FORM_OPEN,
  data,
});
export const deleteDomainFormClose = () => ({
  type: ACTIONS.DELETE_DOMAIN_FORM_CLOSE,
});
export const deleteDomainStart = () => ({
  type: ACTIONS.DELETE_DOMAIN_START,
});
export const deleteDomainFail = (data) => ({
  type: ACTIONS.DELETE_DOMAIN_FAIL,
  data,
});
export const deleteDomainEnd = () => ({
  type: ACTIONS.DELETE_DOMAIN_END,
});
export const loadDomainCountSuccess = (data) => ({
  type: ACTIONS.LOAD_DOMAIN_COUNT_SUCCESS,
  data,
});
export const toggleDomainRulesFormOpen = (data) => ({
  type: ACTIONS.TOGGLE_DOMAIN_RULES_FORM_OPEN,
  data,
});
export const toggleDomainRulesFormClose = () => ({
  type: ACTIONS.TOGGLE_DOMAIN_RULES_FORM_CLOSE,
});
export const toggleDomainRulesStatusStart = () => ({
  type: ACTIONS.TOGGLE_DOMAIN_RULES_STATUS_START,
});
export const toggleDomainRulesStatusEnd = () => ({
  type: ACTIONS.TOGGLE_DOMAIN_RULES_STATUS_END,
});

// Action creators using Thunk middleware.
export const listDomain = (query = '') => {
  const urlParams = new URLSearchParams(query);
  const page = parseInt(urlParams.get('page'), 10) || 1;
  const name = urlParams.get('name');

  const limit = Cookies.get('SHOW_PER_PAGE') || DEFAULT_SHOW_PER_PAGE;

  return (dispatch, getState, { axiosRequest }) => {
    dispatch(listDomainStart());
    return axiosRequest
      .get(DN_API_DOMAINS, {
        params: {
          page,
          limit,
          name,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(listDomainSuccess(response.data));
        }
      })
      .catch(() => {
        toast.error('There is an error while processing your request.');
      })
      .finally(() => {
        dispatch(listDomainEnd());
      });
  };
};
export const readDomainCount = (query) => (dispatch) => {
  const urlParams = new URLSearchParams(query);
  const name = urlParams.get('name');

  return AxiosRequest({
    method: 'get',
    url: DN_API_DOMAIN_COUNT,
    params: { name },
  })
    .then((res) => {
      dispatch(loadDomainCountSuccess(res.data));
    })
    .catch((error) => dispatch(apiCallError(error.response)));
};
export const createDomain = (values, query = '') => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(createDomainStart());

    return axiosRequest
      .post(DN_API_DOMAINS, values)
      .then((response) => {
        if (response.status === 201) {
          dispatch(createDomainFormClose());
          dispatch(readDomainCount());
          dispatch(listDomain(query));
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (
          error.response.status === 409 ||
          error.response.status === 422 ||
          error.response.status === 500
        ) {
          errorMessage = error.response.data.error;
        }
        dispatch(createDomainFail(errorMessage));
      })
      .finally(() => {
        dispatch(createDomainEnd());
      });
  };
};
export const updateDomain = (domainName, payload, query = '') => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(updateDomainStart());

    return axiosRequest
      .put(`${DN_API_DOMAINS}?domain_name=${domainName}`, payload)
      .then((response) => {
        if (response.status === 202) {
          dispatch(updateDomainFormClose());
          dispatch(listDomain(query));
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (
          error.response.status === 409 ||
          error.response.status === 422 ||
          error.response.status === 500
        ) {
          errorMessage = error.response.data.error;
        }
        dispatch(updateDomainFail(errorMessage));
      })
      .finally(() => {
        dispatch(updateDomainEnd());
      });
  };
};
export const deleteDomain = (domain, query = '') => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(deleteDomainStart());

    return axiosRequest
      .delete(`${DN_API_DOMAINS}?domain_name=${domain.name}`)
      .then((response) => {
        if (response.status === 202) {
          dispatch(deleteDomainFormClose());
          dispatch(readDomainCount());
          dispatch(listDomain(query));
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (error.response.status === 500) {
          errorMessage = error.response.data.error;
        }
        dispatch(deleteDomainFail(errorMessage));
      })
      .finally(() => {
        dispatch(deleteDomainEnd());
      });
  };
};

export const toggleDomainRulesStatus = (domain, newStatus) => {
  return (dispatch, getState, { axiosRequest }) => {
    dispatch(toggleDomainRulesStatusStart());

    return axiosRequest
      .put(
        `${DN_API_UPDATE_DOMAIN_RULES_STATUS.replace(
          '{status}',
          newStatus
        )}?domain_name=${domain.name}`
      )
      .then((response) => {
        if (response.status && response.status === 202) {
          dispatch(toggleDomainRulesFormClose());
          dispatch(listDomain());
        }
      })
      .catch((error) => {
        let errorMessage =
          'There is an error while processing your request. Please try again.';
        if (error.response.status === 500) {
          errorMessage = error.response.data.error;
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        dispatch(toggleDomainRulesStatusEnd());
      });
  };
};
