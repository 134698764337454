import { toast } from 'react-toastify';
import axios from 'axios';
import {
  CDN_API_COUNTRY_REGION,
  CDN_API_CDN_COUNTRIES,
  CDN_API_CDN_REGIONS,
  CDN_API_CDN_ACCESSIBILITY,
  CDN_API_CDN_ACCESSIBILITY_ENABLE,
  CDN_API_CDN_ACCESSIBILITY_DELETE,
} from '../../routes/apiRoutes';

const types = {
  CDN_REGION_OPERATION_FAILED: 'CDN_REGION_OPERATION_FAILED',

  LIST_CDN_COUNTRY_REGION_START: 'LIST_CDN_COUNTRY_REGION_START',
  LIST_CDN_COUNTRY_REGION_SUCCESS: 'LIST_CDN_COUNTRY_REGION_SUCCESS',

  LIST_CDN_COUNTRY_START: 'LIST_CDN_COUNTRY_START',
  LIST_CDN_COUNTRY_SUCCESS: 'LIST_CDN_COUNTRY_SUCCESS',
  LIST_CDN_REGION_START: 'LIST_CDN_REGION_START',
  LIST_CDN_REGION_SUCCESS: 'LIST_CDN_REGION_SUCCESS',

  SET_CDN_REGION_ITEM_LIST: 'SET_CDN_REGION_ITEM_LIST',
  SET_COUNTRY_REGION_OPTIONS: 'SET_COUNTRY_REGION_OPTIONS',

  TOGGLE_ADD_CDN_COUNTRY_REGION_MODAL: 'TOGGLE_ADD_CDN_COUNTRY_REGION_MODAL',
  ADD_CDN_COUNTRY_REGION_START: 'ADD_CDN_COUNTRY_REGION_START',
  ADD_CDN_COUNTRY_REGION_SUCCESS: 'ADD_CDN_COUNTRY_REGION_SUCCESS',

  TOGGLE_EDIT_CDN_COUNTRY_REGION_MODAL: 'TOGGLE_EDIT_CDN_COUNTRY_REGION_MODAL',
  EDIT_CDN_COUNTRY_REGION_START: 'EDIT_CDN_COUNTRY_REGION_MODAL_START',
  EDIT_CDN_COUNTRY_REGION_SUCCESS: 'EDIT_CDN_COUNTRY_REGION_SUCCESS',

  TOGGLE_DELETE_CDN_COUNTRY_REGION_MODAL:
    'TOGGLE_DELETE_CDN_COUNTRY_REGION_MODAL',
  DELETE_CDN_COUNTRY_REGION_START: 'DELETE_CDN_COUNTRY_REGION_START',
  DELETE_CDN_COUNTRY_REGION_SUCCESS: 'DELETE_CDN_COUNTRY_REGION_SUCCESS',

  TOGGLE_ENABLE_CDN_COUNTRY_REGION_MODAL:
    'TOGGLE_ENABLE_CDN_COUNTRY_REGION_MODAL',
  ENABLE_CDN_COUNTRY_REGION_START: 'ENABLE_CDN_COUNTRY_REGION_START',
  ENABLE_CDN_COUNTRY_REGION_SUCCESS: 'ENABLE_CDN_COUNTRY_REGION_SUCCESS',
};

const cdnRegionOperationFailed = (data) => ({
  type: types.CDN_REGION_OPERATION_FAILED,
  data,
});

const listCountryRegionStart = () => ({
  type: types.LIST_CDN_COUNTRY_REGION_START,
});
const listCountryRegionSuccess = (data) => ({
  type: types.LIST_CDN_COUNTRY_REGION_SUCCESS,
  data,
});

const listCdnCountryStart = (domain) => ({
  type: types.LIST_CDN_COUNTRY_START,
  data: domain,
});
const listCdnCountrySuccess = (data) => ({
  type: types.LIST_CDN_COUNTRY_SUCCESS,
  data,
});

const listCdnRegionStart = (domain) => ({
  type: types.LIST_CDN_REGION_START,
  data: domain,
});
const listCdnRegionSuccess = (data) => ({
  type: types.LIST_CDN_REGION_SUCCESS,
  data,
});

const setCdnRegionItemList = (data) => ({
  type: types.SET_CDN_REGION_ITEM_LIST,
  data,
});

const setCountryRegionOptions = (data) => ({
  type: types.SET_COUNTRY_REGION_OPTIONS,
  data,
});

const toggleAddCdnCountryRegionModal = (data) => ({
  type: types.TOGGLE_ADD_CDN_COUNTRY_REGION_MODAL,
  data,
});

const addCdnCountryRegionStart = (domain, item) => ({
  type: types.ADD_CDN_COUNTRY_REGION_START,
  data: { domain, item },
});

const addCdnCountryRegionSuccess = (data) => ({
  type: types.ADD_CDN_COUNTRY_REGION_SUCCESS,
  data,
});

const toggleEditCdnCountryRegionModal = (item, form) => ({
  type: types.TOGGLE_EDIT_CDN_COUNTRY_REGION_MODAL,
  data: { item, form },
});

const editCdnCountryRegionStart = (domain, current, form) => ({
  type: types.EDIT_CDN_COUNTRY_REGION_START,
  data: { domain, current, form },
});

const editCdnCountryRegionSuccess = (data) => ({
  type: types.EDIT_CDN_COUNTRY_REGION_SUCCESS,
  data,
});

const toggleDeleteCdnCountryRegionModal = (data) => ({
  type: types.TOGGLE_DELETE_CDN_COUNTRY_REGION_MODAL,
  data,
});

const deleteCdnCountryRegionStart = (domain, item) => ({
  type: types.DELETE_CDN_COUNTRY_REGION_START,
  data: { domain, item },
});

const deleteCdnCountryRegionSuccess = (data) => ({
  type: types.DELETE_CDN_COUNTRY_REGION_SUCCESS,
  data,
});

const toggleEnableCdnCountryRegionModal = (data) => ({
  type: types.TOGGLE_ENABLE_CDN_COUNTRY_REGION_MODAL,
  data,
});

const enableCdnCountryRegionStart = (domain, item) => ({
  type: types.ENABLE_CDN_COUNTRY_REGION_START,
  data: { domain, item },
});

const enableCdnCountryRegionSuccess = (data) => ({
  type: types.ENABLE_CDN_COUNTRY_REGION_SUCCESS,
  data,
});

const listCountryRegion = () => (dispatch, _getState, { axiosRequest }) => {
  dispatch(listCountryRegionStart());
  return axiosRequest
    .get(CDN_API_COUNTRY_REGION)
    .then((response) => {
      if (response.status === 200) {
        dispatch(listCountryRegionSuccess(response.data));
      } else {
        toast.error(response.message);
      }
    })
    .catch((error) => {
      dispatch(cdnRegionOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const listCdnCountry = (domain) => (dispatch, _getState, { axiosRequest }) => {
  dispatch(listCdnCountryStart(domain));
  const url = CDN_API_CDN_COUNTRIES.replace('{domainId}', domain.id);
  return axiosRequest
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        dispatch(listCdnCountrySuccess(response.data));
      } else {
        toast.error(response.message);
      }
    })
    .catch((error) => {
      dispatch(cdnRegionOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const listCdnRegion = (domain) => (dispatch, _getState, { axiosRequest }) => {
  dispatch(listCdnRegionStart(domain));
  const url = CDN_API_CDN_REGIONS.replace('{domainId}', domain.id);
  return axiosRequest
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        dispatch(listCdnRegionSuccess(response.data));
      } else {
        toast.error(response.message);
      }
    })
    .catch((error) => {
      dispatch(cdnRegionOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

const addCdnCountryRegion = (domain, data) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  dispatch(addCdnCountryRegionStart(domain, data));
  const baseUrl = CDN_API_CDN_ACCESSIBILITY.replace('{domainId}', domain.id);

  const { cdns = [] } = data;
  const requests = [];
  Object.entries(cdns).forEach(([key, value]) => {
    if (value) {
      const url = baseUrl.replace('{cdnId}', key);
      const request = axiosRequest.post(url, {
        region_country_code: data.val,
      });
      requests.push(request);
    }
  });

  return axios
    .all(requests)
    .then(
      axios.spread((...responses) => {
        responses.forEach((response) => {
          if (response.status === 201) {
            dispatch(addCdnCountryRegionSuccess(response));
            dispatch(listCdnRegion(domain));
            dispatch(listCdnCountry(domain));
          } else {
            toast.error(response.message);
          }
        });
      })
    )
    .catch((errors) => {
      dispatch(cdnRegionOperationFailed(errors));
      toast.error('There is an error while processing your request.');
    });
};

// TODO
const editCdnCountryRegion = (domain, current, form) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  dispatch(editCdnCountryRegionStart(domain, current, form));
  const baseUrl = CDN_API_CDN_ACCESSIBILITY.replace('{domainId}', domain.id);

  const { cdns = {} } = form;
  const requests = [];

  const { list = [], code } = current;
  const addList = [];
  const deleteList = [];

  const currentCdns = {};
  list.forEach((listItem) => {
    currentCdns[listItem.id] = listItem.isEnabled;
  });

  Object.entries(cdns).forEach(([key, value]) => {
    if (value !== currentCdns[key]) {
      if (value) {
        addList.push(key);
      } else {
        deleteList.push(key);
      }
    }
  });

  addList.forEach((key) => {
    const url = baseUrl.replace('{cdnId}', key);
    const request = axiosRequest.post(url, {
      region_country_code: code,
    });
    requests.push(request);
  });

  deleteList.forEach((key) => {
    const url = baseUrl.replace('{cdnId}', key);
    const request = axiosRequest.delete(url, {
      data: { region_country_code: code },
    });
    requests.push(request);
  });

  return axios
    .all(requests)
    .then(
      axios.spread((...responses) => {
        responses.forEach((response) => {
          if (response.status === 202 || response.status === 201) {
            dispatch(editCdnCountryRegionSuccess(response));
            dispatch(listCdnRegion(domain));
            dispatch(listCdnCountry(domain));
          } else {
            toast.error(response.message);
          }
        });
      })
    )
    .catch((errors) => {
      dispatch(cdnRegionOperationFailed(errors));
      toast.error('There is an error while processing your request.');
    });
};

// TODO - Refactor API endpoint that DELETE request doesn't need body
const deleteCdnCountryRegion = (domain, data) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  dispatch(deleteCdnCountryRegionStart(domain, data));
  const baseUrl = CDN_API_CDN_ACCESSIBILITY_DELETE.replace(
    '{domainId}',
    domain.id
  );

  const { list = [], code } = data;
  const requests = [];
  list.forEach((item) => {
    const url = baseUrl.replace('{cdnId}', item.id);
    const request = axiosRequest.delete(url, {
      data: { region_country_code: code },
    });
    requests.push(request);
  });

  return axios
    .all(requests)
    .then(
      axios.spread((...responses) => {
        responses.forEach((response) => {
          if (response.status === 202) {
            dispatch(deleteCdnCountryRegionSuccess(response));
            dispatch(listCdnRegion(domain));
            dispatch(listCdnCountry(domain));
          } else {
            toast.error(response.message);
          }
        });
      })
    )
    .catch((errors) => {
      dispatch(cdnRegionOperationFailed(errors));
      toast.error('There is an error while processing your request.');
    });
};

const enableCdnCountryRegion = (domain, data) => (
  dispatch,
  _getState,
  { axiosRequest }
) => {
  dispatch(enableCdnCountryRegionStart(domain, data));
  const { id, isEnabled, code } = data;
  const url = CDN_API_CDN_ACCESSIBILITY_ENABLE.replace('{domainId}', domain.id)
    .replace('{cdnId}', id)
    .replace('{status}', !isEnabled);
  return axiosRequest
    .put(url, { region_country_code: code })
    .then((response) => {
      if (response.status === 202) {
        dispatch(enableCdnCountryRegionSuccess(response.data));
        dispatch(listCdnRegion(domain));
        dispatch(listCdnCountry(domain));
      } else {
        toast.error(response.message);
      }
    })
    .catch((error) => {
      dispatch(cdnRegionOperationFailed(error));
      toast.error('There is an error while processing your request.');
    });
};

export default {
  cdnRegionOperationFailed,

  listCountryRegion,
  listCountryRegionStart,
  listCountryRegionSuccess,

  listCdnCountry,
  listCdnCountryStart,
  listCdnCountrySuccess,

  listCdnRegion,
  listCdnRegionStart,
  listCdnRegionSuccess,

  setCdnRegionItemList,
  setCountryRegionOptions,

  addCdnCountryRegion,
  toggleAddCdnCountryRegionModal,
  addCdnCountryRegionStart,
  addCdnCountryRegionSuccess,

  editCdnCountryRegion,
  toggleEditCdnCountryRegionModal,
  editCdnCountryRegionStart,
  editCdnCountryRegionSuccess,

  deleteCdnCountryRegion,
  toggleDeleteCdnCountryRegionModal,
  deleteCdnCountryRegionStart,
  deleteCdnCountryRegionSuccess,

  enableCdnCountryRegion,
  toggleEnableCdnCountryRegionModal,
  enableCdnCountryRegionStart,
  enableCdnCountryRegionSuccess,
};
export { types };
