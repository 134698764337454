import { types } from '../actions/cdnDomainActions';

// Prepare variables.
const initialState = {
  domains: [],
  providers: [],
  isLoading: false,
  error: '',
  isModalOpen: {
    add: false,
    delete: false,
    enable: false,
  },
  selectedDomain: null,
  totalCount: 0,
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case types.LIST_CDN_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.LIST_CDN_DOMAIN_SUCCESS:
      return {
        ...state,
        domains: data,
        isLoading: false,
      };
    case types.CDN_DOMAIN_OPERATION_FAILED:
      return {
        ...state,
        error: data,
        isLoading: false,
      };
    case types.COUNT_CDN_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.COUNT_CDN_DOMAIN_SUCCESS:
      return {
        ...state,
        totalCount: parseInt(data.total_count, 10),
        isLoading: false,
      };
    case types.LIST_CDN_DOMAIN_PROVIDER_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.LIST_CDN_DOMAIN_PROVIDER_SUCCESS:
      return {
        ...state,
        providers: data,
        isLoading: false,
      };
    case types.TOGGLE_CREATE_CDN_DOMAIN_MODAL:
      return {
        ...state,
        isModalOpen: {
          add: !state.isModalOpen.add,
          delete: false,
          enable: false,
        },
      };
    case types.CREATE_CDN_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
        selectedDomain: data,
      };
    case types.CREATE_CDN_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          add: false,
          delete: false,
          enable: false,
        },
      };
    case types.TOGGLE_DELETE_CDN_DOMAIN_MODAL:
      return {
        ...state,
        selectedDomain: data,
        isModalOpen: {
          add: false,
          delete: !state.isModalOpen.delete,
          enable: false,
        },
      };
    case types.DELETE_CDN_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
        selectedDomain: data,
      };
    case types.DELETE_CDN_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          add: false,
          delete: false,
          enable: false,
        },
      };
    case types.TOGGLE_ENABLE_CDN_DOMAIN_MODAL:
      return {
        ...state,
        selectedDomain: data,
        isModalOpen: {
          add: false,
          delete: false,
          enable: !state.isModalOpen.enable,
        },
      };
    case types.ENABLE_CDN_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ENABLE_CDN_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isModalOpen: {
          add: false,
          delete: false,
          enable: false,
        },
      };
    default:
      return state;
  }
};
