import Cookies from 'js-cookie';
import { types } from '../actions/mainActions';
import { DEFAULT_SHOW_PER_PAGE } from '../../utils/constants';

// Prepare variables.
const mainState = {
  settings: {
    currentPath: null,
    checkingSession: false,
  },
  showPerPage: Cookies.get('SHOW_PER_PAGE') || DEFAULT_SHOW_PER_PAGE,
};

export default (state = mainState, action) => {
  switch (action.type) {
    case types.UPDATE_STATE:
      return { ...state, ...action.data };
    case types.UPDATE_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.data,
        },
      };
    case types.UPDATE_SHOW_PER_PAGE:
      return {
        ...state,
        showPerPage: action.data,
      };
    default:
      return state;
  }
};
